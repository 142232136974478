<script setup>
import {inject} from 'vue'
import TooltipComponent from '@/components/widgets/TooltipComponent.vue'
import {useRouter} from 'vue-router'
const router = useRouter()

const props = defineProps(['item'])
const contentData = inject('contentData')

const handleClick = item => {
    if (item.link) {
        router.push(`/info/${item.link}`)
    }
}
</script>

<template>
    <p :class="contentData.what === 'rn' && 'text-black'">
        <!--        <TooltipComponent v-if="props.item.additional_info && contentData.what === 'rn'">-->
        <!--            <span class="text-blue-700 cursor-pointer uppercase">-->
        <!--                {{ props.item.title }}-->
        <!--            </span>-->
        <!--            <template v-slot:tooltip><p v-html="props.item.additional_info"></p></template>-->
        <!--        </TooltipComponent>-->

        <span
            class="uppercase"
            :class="props.item.link ? 'cursor-pointer text-blue-600' : ''"
            @click="handleClick(props.item)">
            {{ props.item.title }}
        </span>
    </p>
    <p
        v-if="contentData.what === 'rn' && !['Misc.', 'General'].includes(item.agenda_session.name)"
        :class="contentData.what === 'rn' && 'text-black'"
        class="italic">
        {{ item.agenda_session.name }}
    </p>
    <p :class="contentData.what === 'rn' && 'text-black'" class="font-bold" v-if="item.author">
        {{ props.item.author.join(', ') }}
    </p>
    <p
        class="text-black"
        v-if="contentData.what === 'rn' && item.agenda_session.name === 'Tutorials & Workshops'"
        v-html="item.topic"></p>
    <p
        class="text-black"
        v-if="contentData.what === 'pht' && item.additional_info"
        v-html="item.additional_info"></p>

    <p
        :class="contentData.what === 'rn' && 'text-black'"
        class="italic text-ellipsis"
        v-if="item.topic && item.agenda_session.name !== 'Tutorials & Workshops'">
        {{ props.item.topic }}
    </p>
</template>

<style scoped></style>
